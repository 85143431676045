/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*!
 * Bootstrap v3.3.2 (http://getbootstrap.com)
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */


.cd-primary-nav .cd-secondary-nav, .cd-primary-nav .cd-nav-gallery, .cd-primary-nav .cd-nav-icons {
  top: 200px!important;
}

.cd-search-trigger {
  text-indent: -9999px;
  background-image: url('../img/ie8/search.png');
  background-repeat: no-repeat;
  background-position: 0 0;
}

.cd-primary-nav .cd-secondary-nav .col:first-child + .col + .col {
  border-right: none;
}

.cd-primary-nav ul.is-hidden {
  z-index: -1;
}

.cd-primary-nav .cd-secondary-nav .col ul ul .go-back {
  display: block;
}

.cd-primary-nav .cd-secondary-nav .col ul ul .go-back a {
  color: white;
}

.cd-primary-nav > .has-children > a {
  /* main navigation arrows on larger devices */
  background-image: url('../img/ie8/down-arrow.png');
  background-repeat: no-repeat;
  background-position: center bottom;
}

.cd-primary-nav .cd-secondary-nav .has-children .has-children > a {
  background-image: url('../img/ie8/right-arrow.png');
  background-repeat: no-repeat;
  background-position: right center;
}

.cd-primary-nav .go-back a {
  text-indent: -9999px;
  background-image: url('../img/ie8/left-arrow.png');
  background-repeat: no-repeat;
  background-position: center;
}


.cd-search input {
  font-size: 3.2em;
  color: #333!important;
}

#myCarousel .carousel-inner > .item > img {
  left: 0;
}

#myCarousel .carousel-inner > .item > picture > img {
  left: 0;
}

.img-banner img {
  left: 0;
}
.carousel-caption {
  left: 0!important;
  width: 100%!important;
}



body, h3, h4, h5, h6, .page-wrapper-inner a, .carousel-caption h1 span {
  font-family: 'Arial';
}
